import React, { useEffect, useState } from 'react'
import { Card, IconButton, TextField } from '@nike/eds'
import useSessionStorage from '../../hooks/useSessionStorage'
import { RequestedSearchForm } from '../../model/RequestSearchForm'

const emptySearchCriteria = () => {
    return {
        project: '',
        environment: '',
    }
}

const emptyValidation = () => {
    return {
        hasErrors: false,
        project: '',
    }
}

export function OverviewSearchForm({onSearch}) {

    const [searchCriteriaState, setSearchCriteria] = useSessionStorage('searchCriteria', emptySearchCriteria())
    const searchCriteria = searchCriteriaState.value
    const [validationError, setValidationError] = useState(emptyValidation())

    const validateSearchCriteria = () => {
        const errors = emptyValidation()
        if (searchCriteria.environment !== '' && searchCriteria.project === '') {
            errors.hasErrors = true
            errors.project = 'Project is required when environment is specified'
        }
        setValidationError(errors)
    }

    const projectChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            project: event.target.value,
        })
    }

    const environmentChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            environment: event.target.value,
        })
    }

    const doSearch = (restore = false) => {
        if (validationError.hasErrors) {
            return
        }
        let searchRequestData: RequestedSearchForm = {
            project: searchCriteria.project,
            environment: searchCriteria.environment,
        }
        onSearch(searchRequestData, restore);
    }

    const submitHandler = (event) => {
        event.preventDefault()
        validateSearchCriteria()
        doSearch()
    }

    const clearSearchCriteria = () => {
        setSearchCriteria(emptySearchCriteria())
    }

    useEffect(() => {
        if (searchCriteriaState.restored) {
            doSearch(true);
        }
    }, [ searchCriteriaState.restored ]);

    return (
        <div className="eds-spacing--ml-20 eds-spacing--mr-20 eds-spacing--mb-20">
            <form onSubmit={submitHandler}>
                <Card className="eds-grid eds-grid--m-cols-3 eds-gap--8 eds-elevation--4">
                    <TextField
                        id="project"
                        label="Project"
                        hasErrors={validationError.project !== ''}
                        errorMessage={validationError.project}
                        value={searchCriteria.project}
                        onChange={projectChangeHandler}
                    />
                    <TextField
                        id="environment"
                        label="Environment"
                        value={searchCriteria.environment}
                        onChange={environmentChangeHandler}
                    />
                    <div className="eds-spacing--p-4 eds-grid--item-justify-end" style={{"display": "flex"}}>
                        <div className="eds-spacing--p-4">
                            <IconButton
                                variant="primary"
                                type={'submit'}
                                size={'small'}
                                icon="Search"
                                label={''} />
                        </div>
                        <div  className="eds-spacing--p-4">
                            <IconButton
                                onClick={clearSearchCriteria}
                                variant="primary"
                                icon="Undo"
                                size={'small'}
                                label={''} />
                        </div>
                    </div>
                </Card>
            </form>
        </div>
    )
}
