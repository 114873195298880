/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const detail = /* GraphQL */ `query Detail($hashKey: String!, $sortKey: String!) {
  detail(hashKey: $hashKey, sortKey: $sortKey) {
    items {
      hashKey
      sortKey
      version
      githubRepo
      githubCommitSha
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.DetailQueryVariables, APITypes.DetailQuery>;
export const list = /* GraphQL */ `query List($limit: Int, $nextToken: String) {
  list(limit: $limit, nextToken: $nextToken) {
    items {
      hashKey
      sortKey
      version
      githubRepo
      githubCommitSha
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListQueryVariables, APITypes.ListQuery>;
export const listByProject = /* GraphQL */ `query ListByProject($hashKey: String!, $limit: Int, $nextToken: String) {
  listByProject(hashKey: $hashKey, limit: $limit, nextToken: $nextToken) {
    items {
      hashKey
      sortKey
      version
      githubRepo
      githubCommitSha
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListByProjectQueryVariables,
  APITypes.ListByProjectQuery
>;
export const listByProjectAndEnvironment = /* GraphQL */ `query ListByProjectAndEnvironment(
  $hashKey: String!
  $sortKey: String!
  $limit: Int
  $nextToken: String
) {
  listByProjectAndEnvironment(
    hashKey: $hashKey
    sortKey: $sortKey
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      hashKey
      sortKey
      version
      githubRepo
      githubCommitSha
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListByProjectAndEnvironmentQueryVariables,
  APITypes.ListByProjectAndEnvironmentQuery
>;
