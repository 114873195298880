import React, {useEffect, useState} from "react";
import {Box, Button, Card, Chip, Icon, Label} from "@nike/eds";
import {VersionManagement} from "../../graphql/api";
import {Link} from "react-router-dom";
import {AppsyncService} from "../services/AppsyncService";
import {oktaAuth} from "../../config/OktaConfig";
import {VersionManagementService} from "../services/VersionManagementService";
import {GithubStatus} from "../shared/GithubStatus";

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

function VersionDetailHeader(props) {

    const [data, setData] = useState<VersionManagement>({} as VersionManagement);

    useEffect(() => {
        const fetchVersionData = async () => {
            var result = await versionManagementService.detail(props.hashKey, props.sortKey);
            setData(result.data.detail.items[0]);
        }
        fetchVersionData();
    }, [props.hashKey, props.sortKey]);

    return (
        <div className="eds-spacing--ml-20 eds-spacing--mr-20">
            <Box className="nesg-grid-items-center">
                <div className="eds-grid eds-gap--8 eds-grid--m-cols-12 eds-elevation--4">
                    <div className="eds-grid--m-col-11 eds-spacing--m-12 eds-spacing--p-4"></div>
                    <div className="eds-grid--m-col-1 eds-spacing--m-12 eds-spacing--p-4">
                        <Button
                            as={Link}
                            to={"/version-overview"}
                            beforeSlot={<Icon name="CaretLeft"/>}>
                            Back
                        </Button>
                    </div>
                </div>
                <div className="eds-grid eds-gap--8 eds-grid--m-cols-8 eds-elevation--4 " style={{backgroundColor: "white"}}>
                    <Card className="eds-grid--m-col-1">
                        <Label font="title-5">
                            Project
                        </Label>
                        <Label font="body-2">
                            <a className="eds-link" target="_blank"
                               href={"https://github.com/" + data.githubRepo + "/customs-inventory." + data.hashKey}>{props.hashKey}</a>
                        </Label>
                    </Card>
                    <Card className="eds-grid--m-col-1">
                        <Label font="title-5">
                            Environment
                        </Label>
                        <Label font="body-2">
                            {props.sortKey.split("#")[0]}
                        </Label>
                    </Card>
                    <Card className="eds-grid--m-col-1">
                        <Label font="title-5">
                            Instance
                        </Label>
                        <Label font="body-2">
                            {props.sortKey.split("#")[1]}
                        </Label>
                    </Card>
                    <Card className="eds-grid--m-col-1">
                        <Label font="title-5">
                            Version
                        </Label>
                        <Label font="body-2">
                            <Chip as="button" size="small" beforeSlot={<Icon name="Bookmark"/>}>{data.version}</Chip>
                        </Label>
                    </Card>
                    <Card className="eds-grid--m-col-1">
                        <Label font="title-5">
                            Status
                        </Label>
                        <Label font="body-2">
                            <GithubStatus searchResult={data} />
                        </Label>
                    </Card>
                    <Card className="eds-grid--m-col-3">
                        <Label font="title-5">
                            Commit SHA
                        </Label>
                        <Label font="body-2">
                            <a className="eds-link" target="_blank"
                               href={"https://github.com/" + data.githubRepo + "/customs-inventory." + data.hashKey + "/commit/" + data.githubCommitSha}>{data.githubCommitSha}</a>
                        </Label>
                    </Card>
                </div>
            </Box>
        </div>
    );
}

export {VersionDetailHeader};
