import React, {useEffect, useState} from "react";
import {Box, Card, Label} from "@nike/eds";
import {VersionManagement} from "../../graphql/api";
import {AppsyncService} from "../services/AppsyncService";
import {oktaAuth} from "../../config/OktaConfig";
import {VersionManagementService} from "../services/VersionManagementService";
import {VersionDetailCommits} from "./VersionDetailCommits";

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const versionManagementService: VersionManagementService = new VersionManagementService(appsyncService)

function VersionDetailCommitInfo(props) {

    const [data, setData] = useState<VersionManagement>({} as VersionManagement);

    useEffect(() => {
        const fetchVersionData = async () => {
            var result = await versionManagementService.detail(props.hashKey, props.sortKey);
            setData(result.data.detail.items[0]);
        }
        fetchVersionData();
    }, [props.hashKey, props.sortKey]);

    return (
        <div>
            <div className="eds-spacing--ml-20 eds-spacing--mr-20">
                <Box className="nesg-grid-items-center">
                    <div className="eds-grid eds-gap--8 eds-grid--m-cols-1 eds-elevation--4 " style={{backgroundColor: "white"}}>

                    </div>
                </Box>
            </div>
            <br/>
            <div>
                <VersionDetailCommits searchResult={data}/>
            </div>
        </div>
    );
}

export {VersionDetailCommitInfo};
