import React, {} from "react";
import { Header } from '../shared/header/Header'
import { Footer } from '../shared/footer/Footer'
import {useParams} from "react-router-dom";
import {VersionDetailHeader} from "../components/detail/VersionDetailHeader";
import {VersionDetailCommitInfo} from "../components/detail/VersionDetailCommitInfo";
import {VersionDetailCommits} from "../components/detail/VersionDetailCommits";


export function VersionDetail(props) {

    let item = {
        hashKey: useParams().id.split('%')[0],
        sortKey : useParams().id.split('%')[1] + "#" + useParams().id.split('%')[2]
    };

    return (
        <div>
            <Header/>
            <br/>
            <VersionDetailHeader hashKey={item.hashKey} sortKey={item.sortKey}/>
            <VersionDetailCommitInfo hashKey={item.hashKey} sortKey={item.sortKey}/>
            <Footer/>
        </div>
    );
}
