import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";

import {
    detail,
    list, listByProject, listByProjectAndEnvironment,
} from '../../graphql/queries'

export class VersionManagementService {

    constructor(private appsyncService: AppsyncService) {
    }

    async list(nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(list), fetchPolicy: 'network-only', variables: {
                    nextToken: nextToken,
                }
            }
        );
    }

    async listByProject(project: string, nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listByProject), fetchPolicy: 'network-only', variables: {
                    hashKey: project,
                    nextToken: nextToken,
                }
            }
        );
    }

    async listByProjectAndEnvironment(project: string, environment: string, nextToken: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listByProjectAndEnvironment), fetchPolicy: 'network-only', variables: {
                    hashKey: project,
                    sortKey: environment,
                    nextToken: nextToken,
                }
            }
        );
    }

    async detail(hashKey: string, sortKey: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(detail), fetchPolicy: 'network-only', variables: {
                    hashKey: hashKey,
                    sortKey: sortKey
                }
            }
        );
    }

}
