import React, {} from "react";
import {Chip, Icon, IconButton, Popover, TableCell} from "@nike/eds";
import {Link} from "react-router-dom";
import {GithubStatus} from "../shared/GithubStatus";

const GithubVersionOverviewTableRow = ({searchResult}) => {

    const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
    let item = {
        environment: searchResult.sortKey.split("#")[0],
        instance : searchResult.sortKey.split("#")[1]
    };

    return (
        <tr>
            <TableCell>
                <a className="eds-link" target="_blank"
                   href={"https://github.com/" + searchResult.githubRepo + "/customs-inventory." + searchResult.hashKey}>{searchResult.hashKey}</a>

            </TableCell>
            <TableCell>{item.environment}</TableCell>
            <TableCell>{item.instance}</TableCell>
            <TableCell>
                <Chip as="button" beforeSlot={<Icon name="Bookmark"/>}>{searchResult.version}</Chip>
            </TableCell>
            <TableCell>
                <a className="eds-link" target="_blank"
                   href={"https://github.com/" + searchResult.githubRepo + "/customs-inventory." + searchResult.hashKey + "/commit/" + searchResult.githubCommitSha}>{searchResult.githubCommitSha}</a>
            </TableCell>
            <TableCell>
                <GithubStatus searchResult={searchResult} />
            </TableCell>
            <TableCell>
                <IconButton
                    variant="ghost"
                    as={Link}
                    to={"details/" + searchResult.hashKey + "%" + searchResult.sortKey.replace("#", "%")}
                    label=""
                    icon="Show"
                />
            </TableCell>
        </tr>
    );
}

function VersionOverviewTableRow({searchResult}) {

    return (
        <GithubVersionOverviewTableRow searchResult={searchResult}/>
    );
}

export {VersionOverviewTableRow};
