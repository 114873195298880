import {OktaAuth} from '@okta/okta-auth-js'
import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync'
export class AppsyncService {

    appSyncClient: AWSAppSyncClient<any>;

    constructor(private oktaAuth: OktaAuth) {
        const { REACT_APP_APPSYNC_ENDPOINT: appsyncEndpoint } = process.env

        this.appSyncClient = new AWSAppSyncClient(
            {
                url: appsyncEndpoint!,
                region: 'eu-west-1', // does not really matter, endpoint is global with latency-based routed targets
                auth: { // The type is AuthOptionsOAuth but IntelliJ/WebStorm is not smart enough here
                    type: AUTH_TYPE.OPENID_CONNECT,
                    jwtToken: async () => oktaAuth.getAccessToken()!,
                },
                disableOffline: true
            });
    }
}
