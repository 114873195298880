import React, {} from "react";
import {Table, TableHeading, TableCell, Skeleton} from "@nike/eds";
import {VersionOverviewTableRow} from "./VersionOverviewTableRow";

function VersionOverviewTable(props) {

    const headers = [
        {Header: "Project"},
        {Header: "Environment"},
        {Header: "Instance"},
        {Header: "Version"},
        {Header: "Commit SHA"},
        {Header: "Status"}
    ];

    return (
        <div className="eds-spacing--ml-20 eds-spacing--mr-20">
            <div className="eds-grid eds-grid--m-cols-6 eds-elevation--4">
                <Table className="eds-grid--m-col-6 eds-grid--item-align-start">
                    <thead>
                    <tr>
                        {headers.map(column => (<TableHeading key={column.Header}>{column.Header}</TableHeading>))}
                    </tr>
                    </thead>
                    <tbody>
                    {props.loading &&
                        <>
                            <tr>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                                <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            </tr>
                        </>
                    }
                    {!props.loading && props.searchResults.map(searchResult => (
                        <VersionOverviewTableRow key={searchResult.hashKey + "#" + searchResult.sortKey}
                                                 searchResult={searchResult}/>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export {VersionOverviewTable};
